import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import building from "../img/building3.webp";
import FloorPlanDetails from "./SkyCityFloorPlan";

const DetailPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        number: "",
        email: "",
        message: "",
    });

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Trigger popup after 5 seconds
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 30000);

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
    };

    const images = [building, building]; // Example image array

    const renderImages = () => {
        const imgCount = images.length;

        return (
            <div className="image-wrapper">
                {imgCount <= 1 ? (
                    <div className="single-page-img1">
                        <div className="img-container img-1">
                            <img src={images[0]} alt="Image 1" />
                        </div>
                    </div>
                ) : imgCount <= 6 ? (
                    <div className={`single-page-img${imgCount}`}>
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="single-page-img6">
                        {images.slice(0, 5).map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                        <div className="img-container img-6">
                            <div className="overlay">{`${imgCount - 5}+ more images`}</div>
                        </div>
                    </div>
                )}

                <div className="add-photos-btn-wrapper">
                    <input
                        type="file"
                        id="common-file-input"
                        className="common-file-input"
                        multiple
                    />
                    <label htmlFor="common-file-input" className="add-photos-btn">
                        + Add Photos
                    </label>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Desktop Image Rendering */}
            <div className="single-page-img">{renderImages()}</div>

            {/* Mobile Slider */}
            <div className="single-page-img-mob">
                <OwlCarousel
                    className="owl-carousel"
                    items={1} // Display one image at a time
                    loop
                    margin={10}
                    nav
                    navText={[
                        '<i class="fas fa-chevron-left"></i>',
                        '<i class="fas fa-chevron-right"></i>'
                    ]}
                    dots // Pagination dots
                >
                    {images.map((img, index) => (
                        <div key={index} className="item">
                            <div className="img-slider-mob">
                                <img src={img} alt={`Slide ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>

            <div className="detail-header">
                <div className="detail-header-list">
                    <div className="detail-building-name">
                        <h2>Building Name</h2>
                    </div>

                    <div className="detail-building-address">
                        <p>123 Main Street, Mira Road, Maharashtra</p>
                    </div>

                    <div className="detail-building-price">
                        <p><strong>Price:</strong> ₹50L</p>
                    </div>
                </div>

                <div className="detail-header-list">
                    <div className="detail-builder-contact">
                        <button className="contact-builder-btn">Contact Builder</button>
                    </div>
                </div>
            </div>

            <div className="detail-list-flex">
                <div className="detail-list-flex1">
                    <div className="detail-page-amenities">
                        <h3>Amenities</h3>
                        <div className="amenities-card">
                            <div className="amenities-list">
                                {/* Amenity 1 */}
                                <div className="amenity-item">
                                    <i className="fas fa-swimming-pool"></i>
                                    <span>Swimming Pool</span>
                                </div>

                                {/* Amenity 2 */}
                                <div className="amenity-item">
                                    <i className="fas fa-dumbbell"></i>
                                    <span>Gym</span>
                                </div>

                                {/* Amenity 3 */}
                                <div className="amenity-item">
                                    <i className="fas fa-parking"></i>
                                    <span>Parking</span>
                                </div>

                                {/* Amenity 4 */}
                                <div className="amenity-item">
                                    <i className="fas fa-elevator"></i>
                                    <span>Elevator</span>
                                </div>

                                {/* Amenity 5 */}
                                <div className="amenity-item">
                                    <i className="fas fa-wifi"></i>
                                    <span>Wi-Fi</span>
                                </div>

                                {/* Amenity 6 */}
                                <div className="amenity-item">
                                    <i className="fas fa-leaf"></i>
                                    <span>Garden</span>
                                </div>

                                {/* Amenity 7 */}
                                <div className="amenity-item">
                                    <i className="fas fa-shield-alt"></i>
                                    <span>24x7 Security</span>
                                </div>

                                {/* Amenity 8 */}
                                <div className="amenity-item">
                                    <i className="fas fa-bolt"></i>
                                    <span>Power Backup</span>
                                </div>

                                {/* Amenity 9 */}
                                <div className="amenity-item">
                                    <i className="fas fa-child"></i>
                                    <span>Children's Play Area</span>
                                </div>

                                {/* Amenity 10 */}
                                <div className="amenity-item">
                                    <i className="fas fa-users"></i>
                                    <span>Community Hall</span>
                                </div>

                                {/* Amenity 11 */}
                                <div className="amenity-item">
                                    <i className="fas fa-basketball-ball"></i>
                                    <span>Sports Court</span>
                                </div>

                                {/* Amenity 12 */}
                                <div className="amenity-item">
                                    <i className="fas fa-hot-tub"></i>
                                    <span>Jacuzzi</span>
                                </div>

                                {/* Amenity 13 */}
                                <div className="amenity-item">
                                    <i className="fas fa-sauna"></i>
                                    <span>Sauna</span>
                                </div>

                                {/* Amenity 14 */}
                                <div className="amenity-item">
                                    <i className="fas fa-shopping-cart"></i>
                                    <span>Convenience Store</span>
                                </div>

                                {/* Amenity 15 */}
                                <div className="amenity-item">
                                    <i className="fas fa-utensils"></i>
                                    <span>Cafeteria</span>
                                </div>

                                {/* Amenity 16 */}
                                <div className="amenity-item">
                                    <i className="fas fa-door-open"></i>
                                    <span>Clubhouse</span>
                                </div>

                                {/* Amenity 17 */}
                                <div className="amenity-item">
                                    <i className="fas fa-street-view"></i>
                                    <span>Walking Trail</span>
                                </div>

                                {/* Amenity 18 */}
                                <div className="amenity-item">
                                    <i className="fas fa-recycle"></i>
                                    <span>Waste Management</span>
                                </div>

                                {/* Amenity 19 */}
                                <div className="amenity-item">
                                    <i className="fas fa-water"></i>
                                    <span>Rainwater Harvesting</span>
                                </div>

                                {/* Amenity 20 */}
                                <div className="amenity-item">
                                    <i className="fas fa-paw"></i>
                                    <span>Pet-Friendly Zone</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FloorPlanDetails />

                    <div className="detail-advantage">
                        <h3>Location advantage</h3>
                        <div className="location-details">
                            <div className="location-list">
                                <ul>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 1</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 2</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 3</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 4</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 5</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 6</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 7</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 8</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 9</li>
                                    <li><i className="fas fa-map-marker-alt"></i> Location 10</li>
                                </ul>
                            </div>
                            <div className="location-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30158.900556182176!2d72.84913415202938!3d19.11368405448357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c83c05b7fc89%3A0xbe87eb057f3aafda!2sAndheri%2C%20Maharashtra%20400053!5e0!3m2!1sen!2sin!4v1735801213508!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: "0" }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                    </div>

                    <div className="detail-youtube">
                        <h3>Virtual Tour</h3>
                        <div className="youtube-link">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/jPkBJY1KI_Q?si=ScTpro5wx0tSRWA6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="detail-list-flex2">
                    {/* Enquiry Form */}
                    <div className="enquiry-form">
                        <h3>Enquire About This Property</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your full name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Phone Number</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="number"
                                        value={formData.number}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your phone number"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    placeholder="Write your message here"
                                ></textarea>
                            </div>
                            <button type="submit">Submit Enquiry</button>
                        </form>
                    </div>
                </div>
            </div>





            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <button className="popup-close-btn" onClick={closePopup}>
                            &times;
                        </button>
                        <div className="popup-content">
                            <h3>Enquire About This Property</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your full name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <input
                                            type="text"
                                            id="number"
                                            name="number"
                                            value={formData.number}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        placeholder="Write your message here"
                                    ></textarea>
                                </div>
                                <button type="submit">Submit Enquiry</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DetailPage;
