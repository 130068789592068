import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../img/Bhoomi_Solutions_Logo_V2.png"

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        {/* Logo Section */}
        <div className="logo">
          <Link to="/" className="logo-text">
            <div className="logo-img">
              {/* <img src={logoIcon} alt="icon" className='logo-img1' /> */}
              <img src={logo} alt="" />
            </div>
            {/* <div>A99000032961</div> */}
          </Link>
        </div>

        <div className="no-brokerage">
          <p>No Brokerage</p>
        </div>

        {/* Hamburger Icon */}
        <div className="hamburger" onClick={toggleDrawer}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </div>

        {/* Drawer Section */}
        <nav className={`nav-links ${isDrawerOpen ? 'drawer-open' : ''}`}>
          <Link to="/" className="nav-link" onClick={toggleDrawer}>Home</Link>
          <Link to="/my-projects" className="nav-link" onClick={toggleDrawer}>My projects</Link>
          <Link to="/about" className="nav-link" onClick={toggleDrawer}>About</Link>
          <Link to="/contact" className="nav-link" onClick={toggleDrawer}>Contact</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
