import React, { useEffect, useState } from "react";

const SkyCityFloorPlan = ({ data }) => {


    // const [selectedPlan, setSelectedPlan] = useState(
    //     data.flatType && Array.isArray(data.flatType) && data.flatType.length > 0
    //         ? data.flatType[0]
    //         : null // Def
    //     // ault to null if flatType is not valid
    // );

    // useEffect(() => {
    //     if (data.flatType && Array.isArray(data.flatType) && data.flatType.length > 0) {
    //         setSelectedPlan(data.flatType[0]);
    //     }
    // }, [data.flatType]);
    const validFlatTypes =
    data.flatType?.filter(flat =>
        flat.builtUpAreas?.some(area => area.value)
    ) || [];

const [selectedPlan, setSelectedPlan] = useState(
    validFlatTypes.length > 0 ? validFlatTypes[0] : null // Default to the first valid flat type or null
);

const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

useEffect(() => {
    if (validFlatTypes.length > 0) {
        setSelectedPlan(validFlatTypes[0]); // Set the first valid flat type as default
    }
}, [data.flatType]);

    return (
        <div className="detail-floor-plan">
            <h3>Floor Plan</h3>
            <div className="floor-plan-tab">
                {data.flatType &&
                    data.flatType
                        .filter((flat) =>
                            flat.builtUpAreas?.some((area) => area.value)
                        ) // Filter only types with valid builtUpAreas.value
                        .map((flat, index) => (
                            <div
                                key={index}
                                className={`floor-plan-tab-item ${selectedPlan?.type === flat.type ? "active" : ""
                                    }`} // Apply "active" class for selected tab
                                onClick={() => setSelectedPlan(flat)}
                            >
                                {flat.type}
                            </div>
                        ))}
            </div>
            {selectedPlan && ( // Render only if selectedPlan is valid
                <div className="floor-plan-details">
                    <div className="floor-plan-image">
                        <img
                            src={`https://100billionoms.com/Images/Store/${selectedPlan.floorPlan}`}
                            alt={`${selectedPlan.type} Image`}
                        />
                    </div>
                    <div className="floor-plan-text">
                        <table>
                            <tbody>
                                {Object.entries(selectedPlan)
                                    .filter(([key]) => key !== "floorPlan") // Exclude "floorplan" key
                                    .map(([key, value], index) => {
                                        let displayKey = key.replace(/([A-Z])/g, " $1").trim(); // Format key names
                                        displayKey = capitalizeFirstLetter(displayKey); // Capitalize first letterdisplayKey = capitalizeFirstLetter(displayKey); // Capitalize first letter
                                        let displayValue;

                                        if (key === "builtUpAreas") {
                                            displayKey = "Carpet Area";
                                            displayValue = value
                                                .filter((item) => item.value) // Ensure valid `value`
                                                .map((item) => item.value)
                                                .join(", ");
                                        } else if (Array.isArray(value)) {
                                            displayValue = value
                                                .filter((item) => {
                                                    return (
                                                        typeof item === "object" &&
                                                        item.expectedPrice
                                                    );
                                                })
                                                .map((item) => {
                                                    const { expectedPrice } = item;
                                                    return `Expected Price: ${expectedPrice}`;
                                                })
                                                .join(" | ");
                                        } else if (typeof value === "object" && value !== null) {
                                            displayValue = Object.entries(value)
                                                .filter(([k]) => k !== "value" && k !== "showMinus")
                                                .map(([k, v]) => `${k}: ${v}`)
                                                .join(", ");
                                        } else {
                                            displayValue = value || "-";
                                        }

                                        if (!displayValue || displayValue === "-") {
                                            return null;
                                        }

                                        return (
                                            <tr key={index}>
                                                <th>{displayKey}</th>
                                                <td>{displayValue}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SkyCityFloorPlan;


// {data.flatType &&
//     data.flatType
//         .filter((flat) =>
//             flat.builtUpAreas.some((area) => area.value) // Ensure valid builtUpAreas
//         )
//         .map((property, propertyIndex) => (
//             <table key={propertyIndex}>
//                 <thead>
//                     <tr>
//                         <th colSpan="2">Property {propertyIndex + 1}</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {Object.entries(property)
//                         .filter(([key]) => key !== "floorPlan") // Exclude "floorplan" key
//                         .map(([key, value], index) => {
//                             let displayKey = key.replace(/([A-Z])/g, " $1").trim(); // Format key names
//                             let displayValue;

//                             // Special case: Change "builtUpAreas" to "Carpet Area" and extract its value
//                             if (key === "builtUpAreas") {
//                                 displayKey = "Carpet Area";
//                                 displayValue = value
//                                     .filter((item) => item.value) // Ensure valid value
//                                     .map((item) => item.value) // Extract value
//                                     .join(", "); // Join multiple values if present
//                             } else if (Array.isArray(value)) {
//                                 // Check if the array contains objects
//                                 displayValue = value
//                                     .filter((item) => {
//                                         return (
//                                             typeof item === "object" &&
//                                             item.expectedPrice
//                                         );
//                                     })
//                                     .map((item) => {
//                                         const { expectedPrice } = item;
//                                         return Expected Price: ${expectedPrice};
//                                     })
//                                     .join(" | ");
//                             } else if (typeof value === "object" && value !== null) {
//                                 // Handle single objects if needed
//                                 displayValue = Object.entries(value)
//                                     .filter(([k]) => k !== "value" && k !== "showMinus") // Exclude unwanted keys
//                                     .map(([k, v]) => ${k}: ${v})
//                                     .join(", ");
//                             } else {
//                                 // Handle primitive values
//                                 displayValue = value || "-";
//                             }

//                             // Skip empty or invalid fields
//                             if (!displayValue || displayValue === "-") {
//                                 return null;
//                             }

//                             return (
//                                 <tr key={index}>
//                                     <th>{displayKey}</th>
//                                     <td>{displayValue}</td>
//                                 </tr>
//                             );
//                         })}
//                 </tbody>
//             </table>
//         ))}

