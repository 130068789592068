import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

const FeaturedCollection = () => {
    let [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    let fetchCategories = async () => {
        try {
            let response = await fetch(`https://100billionoms.com/categoryfeature/getcategory/HBTR0004`);
            let res = await response.json();

            console.log(res);
            // Parse the movie field from JSON string to array of objects
            res = res.filter((category) => (category.status === "Active"))
            res = res.map((category) => ({
                ...category,
                buildings: category.buildings ? JSON.parse(category.buildings) : [],
            }));


            // res=res.filter((cat)=>cat.movie.status==="Active")


            setCategories(res);


        } catch (error) {
            console.log(error);

        }
    };


    useEffect(() => {
        fetchCategories();
    }, [])

    return (
        <>
            {
                categories.map((category) => {
                    return <>
                        <h1 className="feature-heading">{category.category}</h1>
                        <p className="feature-para">Explore projects crafted to redefine luxury living and comfort.</p>
                        <div className="featured-collection">
                            {
                                category.buildings.map((building,index)=>{
                                    return <img
                                    onClick={() => navigate(`/detail-page/${building.property_id}`)}
                                     src={building.formImages && building.formImages[0]
                                        ? `https://100billionoms.com/Images/Store/${building.formImages[0]}`
                                        : building
                                    }
                                        alt={building.Builiding_name || 'Building'} />
                                })
                            }
                            {/* <img src={building} alt="" />
                            <img src={building} alt="" />
                            <img src={building} alt="" />
                            <img src={building} alt="" />
                            <img src={building} alt="" />
                            <img src={building} alt="" /> */}
                        </div>
                    </>
                })
            }
            {/* <h1 className="feature-heading">Featured collections</h1>
            <p className="feature-para">Explore projects crafted to redefine luxury living and comfort.</p>
            <div className="featured-collection">
                <img src={building} alt="" />
                <img src={building} alt="" />
                <img src={building} alt="" />
                <img src={building} alt="" />
                <img src={building} alt="" />
                <img src={building} alt="" />
                 <div className="top-builders">
            {categories.map((category) => (
                <div className="top-builder" key={category.id}>
                    <div className="top-builder-list">
                        <div className="top-builder-img">
                          
                            <img
                                src={
                                    category.buildings[0]?.formImages[0]
                                        ? `https://100billionoms.com/${category.buildings[0].formImages[0]}`
                                        : 'https://via.placeholder.com/300' 
                                }
                                alt="Building"
                                className="builder-bg"
                            />
                           
                            <img
                                src={`https://100billionoms.com/${category.image_logo}`}
                                alt="Logo"
                                className="builder-logo"
                            />
                        </div>
                       
                        <p className="company-name">{category.category}</p>
                       
                        <p className="total-project">{category.buildings.length} total properties</p>
                    </div>
                </div>
            ))}
        </div>
            </div> */}
        </>
    );
};


export default FeaturedCollection;