import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home'
import Header from './Components/Header'
import MyProject from './Components/MyProject'
import DetailPage from './Components/DetailPage';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import SkyCityDetailPage from './Components/SkyCityDetailPage';
import NakshatraAurisDetailPage from './Components/NakshatraAurisDetailPage';
import EvershineDetailPage from './Components/EvershineDetailPage';
import ScrollToTop from './Components/ScrollToTop';
import AvAshirvadDetailPage from './Components/AvAshirvadDetailPage';
import RuturajClassicDetailPage from './Components/RuturajClassicDetailPage';
import FloatingButton from './Components/FloatingButton';
import Gallery from './Components/Gallery';
import "./CSS/Global.css"
import "./CSS/avaas.css"

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/my-projects" element={<MyProject />} />
        <Route path="/detail-page" element={<DetailPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/detail-page/:id" element={<SkyCityDetailPage />} />
        <Route path="/nakshatra-auris-detail-page" element={<NakshatraAurisDetailPage />} />
        <Route path="/evershine-detail-page" element={<EvershineDetailPage />} />
        <Route path="/av-ashirvad-detail-page" element={<AvAshirvadDetailPage />} />
        <Route path="/ruturaj-classic-detail-page" element={<RuturajClassicDetailPage />} />
        <Route path="/gallery/:id" element={<Gallery />} />
      </Routes>
      <FloatingButton />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default App;
