
import { Link, useNavigate } from 'react-router-dom';
import skyCity from '../Sky_city/sky_city_img1.jpg'
import nakshatra from '../Nakshtra_Auris/nakshtra_img1.jpg'
import evershine from '../Evershine/evershine_img1.jpg'
import avAshirwad from '../AV Ashirvad/av_img1.jpg'
import ruturajClassic from '../Ruturaj classic/ruturaj_img1.jpg'
import { useEffect, useState } from 'react';



const getPriceAndAreaDetails = (flatType) => {
    // Filter out flatTypes with builtUpAreas that have valid values
    const validFlats = flatType.filter(
        (flat) =>
            flat.builtUpAreas &&
            flat.builtUpAreas.some(
                (area) => area.value && !isNaN(parseFloat(area.value))
            )
    );

    if (validFlats.length === 0) return { smallest: null, largest: null, typesWithData: [] };

    // Extract all valid builtUpArea values and expectedPrices
    const allBuiltUpAreas = validFlats.flatMap((flat) =>
        flat.builtUpAreas
            .filter(
                (area) =>
                    area.value && area.expectedPrice && !isNaN(parseFloat(area.value))
            )
            .map((area) => ({
                type: flat.type,
                value: parseFloat(area.value),
                expectedPrice: parseFloat(area.expectedPrice),
            }))
    );

    // If there's only one valid entry, return it as both smallest and largest
    if (allBuiltUpAreas.length === 1) {
        return {
            smallest: allBuiltUpAreas[0],
            largest: allBuiltUpAreas[0],
            typesWithData: [allBuiltUpAreas[0].type],
        };
    }

    // Find the smallest and largest entries across all types
    const smallest = allBuiltUpAreas.reduce((prev, curr) =>
        curr.value < prev.value ? curr : prev
    );

    const largest = allBuiltUpAreas.reduce((prev, curr) =>
        curr.value > prev.value ? curr : prev
    );

    // Collect all unique types with valid data
    const typesWithData = [
        ...new Set(allBuiltUpAreas.map((entry) => entry.type)),
    ];

    return { smallest, largest, typesWithData };
};

const formatPrice = (price) => {
    const num = parseInt(price, 10);
    if (isNaN(num)) return "Invalid Price";

    if (num >= 10000000) {
        // Convert to Crores
        return `${(num / 10000000).toFixed(1)} Cr`;
    } else if (num >= 100000) {
        // Convert to Lakhs
        return `${(num / 100000).toFixed(1)} L`;
    } else if (num >= 1000) {
        // Convert to Thousands
        return `${(num / 1000).toFixed(1)} K`;
    } else {
        // For amounts less than 1000
        return num.toString();
    }
};


const MyProject = () => {
    const [data, setData] = useState([])
    const navigate=useNavigate()

    const fetchData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/property/getproperty/HBTR0004`);
            const data = await response.json();

            // Safely parse fields in each object
            const parseJSON = (data) => {
                try {
                    return JSON.parse(data);
                } catch {
                    return data; // Return original data if parsing fails
                }
            };

            // Iterate over the array of objects if the response contains multiple objects
            const parsedData = data.map((item) => {
                return {
                    ...item,
                    amenities: parseJSON(item.amenities || "[]"),
                    carpetArea: parseJSON(item.carpetArea || "[]"),
                    flatType: parseJSON(item.flatType || "[]"),
                    formImages: parseJSON(item.formImages || "[]"),
                    locationAdvantages: parseJSON(item.locationAdvantages || "[]"),
                };
            });

            console.log(parsedData);
            setData(parsedData.reverse())
        } catch (error) {
            console.error("Error fetching or parsing data:", error);
        }
    };




    useEffect(() => {
        fetchData();
    }, [])


    return (
        <>
            <div className="my-project-cards">
            {data.map((card) => {
                     const { smallest, largest, typesWithData } = getPriceAndAreaDetails(card.flatType);
                    return <>
                        <div className="home-new-card">
                            <div className="home-new-img" onClick={() => navigate(`/detail-page/${card.property_id}`)}>
                                <img src={`https://100billionoms.com/Images/Store/${card.formImages[0]}`} alt="Building" />
                            </div>
                            <div className="home-new-details">
                                <div className="new-home-heading-share" onClick={() => navigate(`/detail-page/${card.property_id}`)}>
                                    <h2 className='buildingname-check'>{card.building_name}</h2>
                                    {/* <div className="new-share-btn">
                                        <i className="fa-solid fa-share-nodes"></i>
                                    </div> */}
                                </div>
                                {/* <p className="home-new-builder-name" onClick={() => navigate(`/detail-page/${card.property_id}`)}>By Sneha Property</p> */}
                                <div className="home-new-location-bhks" onClick={() => navigate(`/detail-page/${card.property_id}`)}>
                                    <p className="home-new-location">
                                        {card.city}
                                    </p>
                                    <p className="home-new-bhk">
                                        {
                                            typesWithData.join("- ") == "" || typesWithData === null ? <span > {card.bedrooms} BHK</span> :
                                                <span > {typesWithData.join("- ")}</span>
                                        }</p>
                                </div>
                                <div className="home-new-price-btn">

                                    <p className="home-new-price" onClick={() => navigate(`/detail-page/${card.property_id}`)}>
                                        ₹ {formatPrice(smallest?.expectedPrice || 0)} - ₹ {formatPrice(largest?.expectedPrice || 0)}
                                    </p>

                                    <button className="home-new-btn"
                                    // onClick={() => setShowPopup(true)}
                                    >Contact</button>
                                </div>
                            </div>
                        </div>
                    </>
                    // <Link to={`/detail-page/${ele.property_id}`} key={ele.id}>
                    //     <div className="my-project-card">
                    //         <img src={`https://100billionoms.com/Images/Store/${ele.formImages[0]}`} alt={ele.building_name} />
                    //         <div className="my-project-details">
                    //             <h2>{ele.building_name}</h2>
                    //             <p>{ele.city}</p>
                    //         </div>
                    //     </div>
                    // </Link>
                })}
            </div>
        </>
    );
};

export default MyProject;
