import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AVimg1 from '../AV Ashirvad/av_img1.jpg'
import AVimg2 from '../AV Ashirvad/av_img2.jpg'
import AVimg3 from '../AV Ashirvad/av_img3.jpg'
import AVimg4 from '../AV Ashirvad/av_img4.jpg'
import AVimg5 from '../AV Ashirvad/av_img5.jpg'
import NakshatraFloorPlan from "./NakshatraFloorPlan";
import AvAshirwadFloorPlan from "./AvAshirwadFloorPlan";
import { useNavigate } from "react-router-dom";

const AvAshirvadDetailPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        number: "",
        email: "",
        message: "",
    });

    const navigate = useNavigate();

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Trigger popup after 5 seconds
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 30000);

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
    };

    const images = [AVimg1, AVimg2, AVimg3, AVimg4, AVimg5]; // Example image array

    const renderImages = () => {
        const imgCount = images.length;

        return (
            <div className="image-wrapper">
                {imgCount <= 1 ? (
                    <div className="single-page-img1">
                        <div className="img-container img-1">
                            <img src={images[0]} alt="Image 1" />
                        </div>
                    </div>
                ) : imgCount <= 6 ? (
                    <div className={`single-page-img${imgCount}`}>
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="single-page-img6">
                        {images.slice(0, 5).map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                        <div className="img-container img-6">
                            <div className="overlay">{`${imgCount - 5}+ more images`}</div>
                        </div>
                    </div>
                )}

                <div className="add-photos-btn-wrapper">
                    <input
                        type="file"
                        id="common-file-input"
                        className="common-file-input"
                        multiple
                    />
                    <label htmlFor="common-file-input" className="add-photos-btn">
                        + Add Photos
                    </label>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Desktop Image Rendering */}
            <div className="single-page-img">{renderImages()}</div>

            {/* Mobile Slider */}
            <div className="single-page-img-mob">
                <OwlCarousel
                    className="owl-carousel"
                    items={1} // Display one image at a time
                    loop
                    margin={10}
                    nav
                    navText={[
                        '<i class="fas fa-chevron-left"></i>',
                        '<i class="fas fa-chevron-right"></i>'
                    ]}
                    dots // Pagination dots
                >
                    {images.map((img, index) => (
                        <div key={index} className="item">
                            <div className="img-slider-mob">
                                <img src={img} alt={`Slide ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>

            <div className="detail-header">
                <div className="detail-header-list">
                    <div className="detail-building-name">
                        <h2>AV ashirwad garden</h2>
                    </div>

                    <div className="detail-building-address">
                        <p>-</p>
                    </div>

                    <div className="detail-building-price">
                        <p><strong>Price:</strong>₹ 32L</p>
                    </div>
                </div>

                <div className="detail-header-list">
                    <div className="detail-builder-contact">
                        <a href="tel:+918446064275"> <button className="contact-builder-btn">Contact Builder</button></a>
                    </div>
                </div>
            </div>

            <div className="detail-list-flex">
                <div className="detail-list-flex1">
                    <div className="detail-page-amenities">
                        <h3>Amenities</h3>
                        <div className="amenities-content">
                            <ul>
                                <li>Kids Play Area</li>
                                <li>24hrs CCTV</li>
                                <li>24X7 Power Backup</li>
                                <li>OPEN SKY GYM</li>
                                <li>Rooftop Jogging Track</li>
                                <li>EV Charging Station</li>
                                <li>Serene Yoga Deck</li>
                                <li>Senior Citizen Corner Area</li>
                                <li>Meditation</li>
                                <li>Landscape Garden</li>
                                <li>24x7 Doctor On Call</li>
                                <li>Star Gazing</li>
                                <li>Fire Fighting System in Each Flat</li>
                            </ul>

                        </div>
                    </div>

                    <AvAshirwadFloorPlan />

                    <div className="detail-advantage">
                        <h3>Location advantage</h3>
                        <div className="location-details">
                            <div className="location-list">
                                <ul>

                                    <li><i className="fas fa-map-marker-alt"></i> Arise Hospital & ICU <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Riddhi Vinayak Multi Speciality Hospital <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Ayush Hospital <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Vaarush Multispeciality Hospital <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Niswarth Hospital <span>(Nearby)</span></li>


                                    <li><i className="fas fa-map-marker-alt"></i> Rahul International School - ICSE <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> K.L. Tiwari Degree College <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Mother Mary’s English High School <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> St. Anne’s High School <span>(Nearby)</span></li>


                                    <li><i className="fas fa-map-marker-alt"></i> Domino’s <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Zudio <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Vijay Sales <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Reliance Trends <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Burger King <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> KFC <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> McDonald’s <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Croma <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> D’Mart <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Kalamb Beach <span>(Nearby)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Rajodi Beach <span>(Nearby)</span></li>

                                    <li><i className="fas fa-map-marker-alt"></i> NSP Railway Station <span>(8 mins)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Virar Railway Station <span>(12 mins)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> NSP East-West Fly Over <span>(5 mins)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Virar-NSP Link Road <span>(1 min)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Bus Depot <span>(7 mins)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Bullet Train Station <span>(20 mins)</span></li>
                                </ul>
                            </div>
                            <div className="location-map">
                                {/* <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.749311958006!2d72.80232747374679!3d19.423234540982747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a95b21e3e697%3A0x4a90644a230b294b!2sNakshatra%20Auris!5e0!3m2!1sen!2sin!4v1736430529496!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                /> */}
                            </div>
                        </div>
                    </div>

                    <div className="detail-youtube">
                        <h3>Virtual Tour</h3>
                        <div className="youtube-link">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/jPkBJY1KI_Q?si=ScTpro5wx0tSRWA6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="detail-list-flex2">
                    {/* Enquiry Form */}
                    <div className="enquiry-form">
                        <h3>Enquire About This Property</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your full name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Phone Number</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="number"
                                        value={formData.number}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your phone number"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    placeholder="Write your message here"
                                ></textarea>
                            </div>
                            <button type="submit">Submit Enquiry</button>
                        </form>
                    </div>
                </div>
            </div>





            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <button className="popup-close-btn" onClick={closePopup}>
                            &times;
                        </button>
                        <div className="popup-content">
                            <h3>Enquire About This Property</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your full name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <input
                                            type="text"
                                            id="number"
                                            name="number"
                                            value={formData.number}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        placeholder="Write your message here"
                                    ></textarea>
                                </div>
                                <button type="submit">Submit Enquiry</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AvAshirvadDetailPage;
