import React, { useState } from "react";

const AvAshirwadFloorPlan = () => {
    const floorPlans = [
        {
            type: "1 BHK Property",
            carpetArea: "445 sq.ft",
            builtUpArea: "640 sq.ft",
            numberOfFloors: "14",
            floorLevel: "All",
            furnishing: "Semi-Furnished",
            bedrooms: "1",
            bathrooms: "2",
            balconies: "3",
            availability: "Under Construction",
            propertyAge: "New",
            ownership: "-",
            facing: "Garden & Road",
            price: "₹32.51 Lakhs",
            maintenance: "₹0/month",
            parking: "Open Parking Available",
            securityDeposit: "₹0",
            possessionDate: "11 Nov 2026",
            image: "https://via.placeholder.com/400x300?text=1+BHK+Property"
        },
        {
            type: "2 BHK Property",
            carpetArea: "561 sq.ft",
            builtUpArea: "900 sq.ft",
            numberOfFloors: "14",
            floorLevel: "All",
            furnishing: "Semi-Furnished",
            bedrooms: "2",
            bathrooms: "2",
            balconies: "4",
            availability: "Under Construction",
            propertyAge: "New",
            ownership: "Builder",
            facing: "-",
            price: "₹45.99 Lakhs",
            maintenance: "₹0/month",
            parking: "Open Parking Available",
            securityDeposit: "₹0",
            possessionDate: "11 Nov 2026",
            image: "https://via.placeholder.com/400x300?text=2+BHK+Property"
        }
    ];

    const [selectedPlan, setSelectedPlan] = useState(floorPlans[0]);

    return (
        <div className="detail-floor-plan">
            <h3>Floor Plan</h3>
            <div className="floor-plan-tab">
                {floorPlans.map((plan, index) => (
                    <div
                        key={index}
                        className={`floor-plan-tab-item ${selectedPlan.type === plan.type ? "active" : ""}`}
                        onClick={() => setSelectedPlan(plan)}
                    >
                        {plan.type}
                    </div>
                ))}
            </div>

            <div className="floor-plan-details">
                <div className="floor-plan-image">
                    <img src={selectedPlan.image} alt={`${selectedPlan.type} Image`} />
                </div>
                <div className="floor-plan-text">
                    <table>
                        <tbody>
                            {Object.entries(selectedPlan).map(([key, value]) => {
                                if (key !== "image" && key !== "type") {
                                    return (
                                        <tr key={key}>
                                            <th>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}</th>
                                            <td>{value}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AvAshirwadFloorPlan;
