import '../CSS/Global.css'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const TopBuilders = () => {

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/categoryfeaturedev/getcategory/HBTR0004`);
            let res = await response.json();

            res = res
                .filter((category) => category.status === "Active")
                .map((category) => ({
                    ...category,
                    buildings: category.buildings ? JSON.parse(category.buildings) : [],
                }));

            setCategories(res);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <h1 className="feature-heading">Featured developers</h1>
            <p className="feature-para">
                {/* Explore projects crafted to redefine luxury living and comfort. */}
            </p>
            <div className="top-builder">
                {categories.map((category) => (
                    <>
                        <div className="top-builder-list" 
                        // onClick={() => navigate(`/developer/${category.category}`)}
                        >
                            <div className="top-builder-img">
                                {/* Building Image */}
                                <img
                                    src={
                                        category.buildings[0]?.formImages[0]
                                            ? `https://100billionoms.com/Images/Store/${category.buildings[0].formImages[0]}`
                                            : 'https://via.placeholder.com/300' // Placeholder for missing images
                                    }
                                    alt="Building"
                                    className="builder-bg"
                                />
                                {/* Logo Image */}
                                <img
                                    src={`https://100billionoms.com/${category.image_logo}`}
                                    alt="Logo"
                                    className="builder-logo"
                                />
                            </div>
                            {/* Category Name */}
                            <p className="company-name">{category.category}</p>
                            {/* Total Properties */}
                            <p className="total-project">{category.buildings.length} total properties</p>
                        </div>
                    </>
                ))}
            </div>
            {/* <div className="top-builder">
                <div className="top-builder-list">
                    <div className="top-builder-img">
                        <img src={building} alt="" className='builder-bg' />
                        <img src={lodha} alt="" className='builder-logo' />
                    </div>
                    <p className="company-name">Lodha</p>
                    <p className="total-project">32 total properties</p>
                </div>
               
            </div> */}
        </>
    );
};

export default TopBuilders;