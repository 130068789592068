import React, { useState } from "react";
import evershineImg from "../Nakshtra_Auris/nakshtra_img2.jpg";

const EvershineFloorPlan = () => {
    const floorPlans = [
        {
            type: "1 BHK Property",
            carpetArea: "473-511 sq.ft",
            builtUpArea: "-",
            numberOfFloors: "14",
            floorLevel: "All",
            furnishing: "Semi-Furnished",
            bedrooms: "1 BHK",
            bathrooms: "2",
            balconies: "3",
            availability: "Under construction and Ready to Move",
            propertyAge: "new and Under construction",
            ownership: "Builder",
            facing: "-",
            price: "₹41.50L - ₹44.50L",
            maintenance: "₹0",
            parking: "Open Parking",
            securityDeposit: "₹0",
            possessionDate: "Dec 2025",
            readyDate: "April 2024",
            image: "https://via.placeholder.com/400x300?text=1+BHK+Property",
        },
        {
            type: "2 BHK Property",
            carpetArea: "617-702 sq.ft",
            builtUpArea: "-",
            numberOfFloors: "14",
            floorLevel: "All",
            furnishing: "Semi-Furnished",
            bedrooms: "2 BHK",
            bathrooms: "2",
            balconies: "4",
            availability: "Under construction and ready to move",
            propertyAge: "new and Under construction",
            ownership: "Builder",
            facing: "-",
            price: "₹55.50L - ₹65.50L",
            maintenance: "₹0",
            parking: "Open Parking",
            securityDeposit: "₹0",
            possessionDate: "Dec 2025",
            readyDate: "April 2024",
            image: "https://via.placeholder.com/400x300?text=2+BHK+Property",
        },
    ];

    const [selectedPlan, setSelectedPlan] = useState(floorPlans[0]);

    return (
        <div className="detail-floor-plan">
            <h3>Floor Plan</h3>
            <div className="floor-plan-tab">
                {floorPlans.map((plan, index) => (
                    <div
                        key={index}
                        className={`floor-plan-tab-item ${selectedPlan.type === plan.type ? "active" : ""}`}
                        onClick={() => setSelectedPlan(plan)}
                    >
                        {plan.type}
                    </div>
                ))}
            </div>

            <div className="floor-plan-details">
                <div className="floor-plan-image">
                    <img src={selectedPlan.image} alt={`${selectedPlan.type} Image`} />
                </div>
                <div className="floor-plan-text">
                    <table>
                        <tbody>
                            {Object.entries(selectedPlan).map(([key, value]) => {
                                if (key !== "image" && key !== "type") {
                                    return (
                                        <tr key={key}>
                                            <th>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}</th>
                                            <td>{value}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EvershineFloorPlan;
