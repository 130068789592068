import React, { useState, useEffect } from 'react';
import Logo from "../img/Red with X Band Logo.png";
import { toast, ToastContainer } from 'react-toastify';

const FloatingButton = () => {
  const fullUrl = window.location.href;
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    remark: "",
    agreeToContact: false,
    address: "",
    user_id: "HBTR0004",
    enquiry_source: "Float Button",
    source: fullUrl,
    role: "",
  });

  const convertToINRWords = (amount) => {
    const words = [
      "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
      "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
      "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    const numToWords = (num) => {
      if (num < 20) return words[num];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + words[num % 10] : "");
      if (num < 1000) return words[Math.floor(num / 100)] + " Hundred" + (num % 100 !== 0 ? " and " + numToWords(num % 100) : "");
      if (num < 100000) return numToWords(Math.floor(num / 1000)) + " Thousand" + (num % 1000 !== 0 ? " " + numToWords(num % 1000) : "");
      if (num < 10000000) return numToWords(Math.floor(num / 100000)) + " Lakh" + (num % 100000 !== 0 ? " " + numToWords(num % 100000) : "");
      return numToWords(Math.floor(num / 10000000)) + " Crore" + (num % 10000000 !== 0 ? " " + numToWords(num % 10000000) : "");
    };

    if (isNaN(amount)) return "";

    const wholePart = Math.floor(amount);
    const result = numToWords(wholePart);

    return `Rupees ${result} Only`;
  };

  const [load, setLoad] = useState(true)

  useEffect(() => {
    // Check if the popup was already shown
    const popupShown = sessionStorage.getItem('popupShown');
    if (!popupShown) {
      // Trigger popup after 30 seconds
      const timer = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('popupShown', 'true'); // Set flag in sessionStorage
      }, 30000);

      return () => clearTimeout(timer); // Clean up the timer on component unmount
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start showing the spinner
    setLoad(false); // Disable button to prevent multiple submissions during loading

    // Simulate delay for spinner
    setTimeout(() => {
      setIsLoading(false); // Hide the spinner after 3 seconds (or on response completion)
    }, 3000);

    try {
      // Sending the form data to the server
      const response = await fetch(`https://100billionoms.com/leads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        // Reset form fields after submission
        setFormData({
          name: "",
          mobile: "",
          remark: "",
          address: "",
          user_id: "HBTR0004",
          enquiry_source: "Contact Page",
          source: fullUrl,
          role: "",
        });
        closePopup();
        // Display success toast message
        setLoad(true);
        toast.success('Details sent, Our team will contact you soon!');
      } else {
        // If response is not OK, show an error message
        setLoad(true);
        toast.error('Error, Try Again');
      }
    } catch (error) {
      // Handle any errors during submission
      console.log(error);
      toast.error('Error, Try Again');
      setLoad(true);
    }
  };

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="floating-btn-container">
        {/* Main Floating Button */}
        <button
          className={`floating-btn ${!isOpen ? "bounce" : ""}`}
          onClick={toggleMenu}
        >
          {isOpen ? (
            <i className="fas fa-times"></i> // X mark icon when open
          ) : (
            <img src={Logo} alt="Logo" />
          )}
        </button>

        {/* Options */}
        <div className={`options-menu ${isOpen ? "open" : "closed"}`}>
          <a href="tel:+919987809718" className="option">
            <i className="fas fa-phone option-icon"></i>
            <span>Call</span>
          </a>
          <div className='option' onClick={() => setShowPopup(true)}>
            <i className="fas fa-envelope option-icon"></i>
            <span>Enquiry</span>
          </div>
          <a
            href="https://wa.me/9987809718?text=Hello%21%20I%20am%20interested%20in%20properties."
            className="option"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp option-icon"></i>
            <span>WhatsApp</span>
          </a>


        </div>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="popup-close-btn" onClick={closePopup}>
              &times;
            </button>
            <div className="popup-content">
              <h3>Enquire About This Property</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Enter your full name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="number">Phone Number</label>
                    <input
                      type="text"
                      id="number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                      placeholder="Enter your phone number"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="number">Location<p className='location-newaskuser'>*Enter your location where you finding a property</p></label>
                    
                    <input
                      type="text"
                      id="number"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                      placeholder="Enter location"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="number">Budget</label>
                    <input
                      type="number"
                      id="number"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      required
                      placeholder="Enter your Budget"
                    />
                    {
                      formData.role > 0 &&
                      <small style={{ fontSize: "12px", color: "#1DAC4B", fontWeight: "600", marginTop: "3px" }}>
                        {convertToINRWords(formData.role)}
                      </small>
                    }
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}

                    placeholder="Write your message here"
                  ></textarea>
                </div>
                {
                  load ? <button type="submit">Submit Enquiry</button> :
                    <button type="button">Loading...</button>
                }
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingButton;
