import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Gallery = () => {
    // Array of images (using Lorem Picsum for now)
    const images = [
        'https://picsum.photos/id/1015/400/300',
        'https://picsum.photos/id/1016/400/300',
        'https://picsum.photos/id/1018/400/300',
        'https://picsum.photos/id/1021/400/300',
        'https://picsum.photos/id/1022/400/300',
        'https://picsum.photos/id/1025/400/300',
        'https://picsum.photos/id/1024/400/300',
        'https://picsum.photos/id/1029/400/300',
        'https://picsum.photos/id/1031/400/300',
        'https://picsum.photos/id/1033/400/300'
    ];
    const { id } = useParams()
    const navigate = useNavigate()
    const login = JSON.parse(localStorage.getItem("login")) || ""
    // State for the carousel (tracking the current index)
    const [currentImage, setCurrentImage] = useState(0);

    // State to track whether the gallery is open or closed
    const [isGalleryOpen, setIsGalleryOpen] = useState(true);

    // Handle carousel navigation
    const nextImage = () => {
        setCurrentImage((prevIndex) => (prevIndex + 1) % (storeSingle.formImages?.length || images.length));
    };

    const prevImage = () => {
        setCurrentImage((prevIndex) =>
            prevIndex === 0 ? (storeSingle.formImages?.length || images.length) - 1 : prevIndex - 1
        );
    };

    // Handle closing the gallery
    const closeGallery = () => {
        navigate(-1)
    };

    const [storeSingle, setSinglevendor] = useState({})
    const detailVendorGet = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/property/getsingleproperty/${id}`)
            const res = await response.json();
            console.log(res);
            const parseJSON = (data) => {
                try {
                    return JSON.parse(data);
                } catch {
                    return data; // Return the original data if parsing fails
                }
            };

            res.amenities = parseJSON(res.amenities || "[]");
            res.carpetArea = parseJSON(res.carpetArea || "[]");
            res.flatType = parseJSON(res.flatType || "[]");
            res.formImages = parseJSON(res.formImages || "[]");
            res.locationAdvantages = parseJSON(res.locationAdvantages || "[]");
            setSinglevendor(res);

        } catch (error) {
            console.log(error)
        }
    }

    const handleWhatsAppClick = (storeName, mobile) => {
        console.log("Store Name:", storeName); // Check if storeName is correct
        console.log("Mobile Number:", mobile); // Check if mobile number is correct

        const message = `Hi, I would like to inquire about ${storeName}`;
        const phoneNumber = `7020810171`; // Replace with the store's WhatsApp number

        console.log("WhatsApp URL:", `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`);

        window.open(`https://wa.me/7020810171?text=Hello%21%20I%20am%20interested%20in%20properties.`, '_blank');
    };


    const handleShareClick = (name, idN) => {
        const shareData = {
            title: "Check this out!",
            text: `Check this out!,  Visit our website for more details! https://snehaproperty.com/detail-page/${idN}`,
            // url: `Visit our website for more details! https://snehaproperty.com/detail-page/${idN}` // Replace with your website URL
        };

        // Check if the browser supports the navigator.share API
        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Successfully shared'))
                .catch((error) => console.error('Error sharing', error));
        } else {
            // Fallback: Copy the link to the clipboard for desktop users
            navigator.clipboard.writeText(shareData.text)
                .then(() => alert('Link copied to clipboard!'))
                .catch((error) => console.error('Could not copy link', error));
        }
    };

    useEffect(() => {
        detailVendorGet();
    }, [])

    // Only render the gallery if it's open
    return (
        <>
            {isGalleryOpen && (
                <div className="gallery">
                    <div className='gallery-info'>
                        <div className='gallery-business-name'>
                            {storeSingle && storeSingle.building_name}
                        </div>
                        <div className='gallery-contact-btn'>
                            <Link to={`tel:+919987809718`} style={{color:"white"}}>
                                <button className='contact-gallery'><i className="fa-solid fa-phone"></i>Call</button>
                            </Link>
                            <Link style={{color:"white",fontWeight:"600"}}
                                to="https://wa.me/9987809718?text=Hello%21%20I%20am%20interested%20in%20properties."
                                
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className='whatsapp-gallery' ><i className="fa-brands fa-whatsapp"></i>whatsapp</button>
                            </Link>
                            {/* {
                login.permanent_ID === storeSingle.user_id ? <button className='add-images' ><i className="fa-solid fa-images"></i>add images</button> : null
              } */}
                            {/* <button className='share-gallery' onClick={() => handleShareClick(storeSingle.building_name, storeSingle.property_id)}><i  class="fa-solid fa-share"></i></button> */}
                            <button className='close-gallery' onClick={closeGallery}><i  className="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>

                    <div className="gallery-images">
                        {/* Left Side with Static Images */}
                        <div className="gallery-left-side">
                            {storeSingle?.formImages && storeSingle.formImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={`https://100billionoms.com/Images/Store/${image}`}
                                    alt={`Gallery Image ${index + 1}`}
                                    className={`gallery-left-img ${index === currentImage ? 'highlight-border' : ''}`} // Add conditional class
                                    onClick={() => setCurrentImage(index)}
                                />
                            ))}
                        </div>
                        {/* Right Side with Carousel */}
                        <div className="gallery-right-side">
                            <div className="gallery-carousel">
                                <button className="gallery-prev-btn" onClick={prevImage}>
                                    <i className="fa-solid fa-chevron-left"></i>
                                </button>
                                <img src={`https://100billionoms.com/Images/Store/${storeSingle?.formImages?.[currentImage]}`} alt={`Gallery Carousel Image`} className="gallery-carousel-img" />
                                <button className="gallery-next-btn" onClick={nextImage}>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Gallery;
