import React, { useState } from "react";
import nakshraImg1 from '../Nakshtra_Auris/nakshtra_img2.jpg'

const NakshatraFloorPlan = () => {
    const floorPlans = [
        {
            type: "1 BHK Property",
            carpetArea: "375-400 sq.ft",
            builtUpArea: "-",
            numberOfFloors: "18",
            floorLevel: "All",
            furnishing: "Semi Furnished",
            bedrooms: "1 BHK",
            bathrooms: "2",
            balconies: "3",
            availability: "Under Construction",
            propertyAge: "Under",
            ownership: "Builder",
            facing: "-",
            price: "₹32.60L - ₹34.85L (Flat Cost)",
            maintenance: "₹0",
            parking: "Open Parking",
            securityDeposit: "₹0",
            possessionDate: "Dec 2025",
            image: nakshraImg1,
        },
        {
            type: "2 BHK Property",
            carpetArea: "536-554 sq.ft",
            builtUpArea: "-",
            numberOfFloors: "18",
            floorLevel: "All",
            furnishing: "Semi Furnished",
            bedrooms: "2 BHK",
            bathrooms: "2",
            balconies: "4",
            availability: "Under Construction",
            propertyAge: "Under",
            ownership: "Builder",
            facing: "-",
            price: "₹48.50L - ₹52L (Flat Cost)",
            maintenance: "₹0",
            parking: "Open Parking",
            securityDeposit: "₹0",
            possessionDate: "Dec 2025",
            image: nakshraImg1,
        },
    ];

    const [selectedPlan, setSelectedPlan] = useState(floorPlans[0]);

    return (
        <div className="detail-floor-plan">
            <h3>Floor Plan</h3>
            <div className="floor-plan-tab">
                {floorPlans.map((plan, index) => (
                    <div
                        key={index}
                        className={`floor-plan-tab-item ${selectedPlan.type === plan.type ? "active" : ""}`}
                        onClick={() => setSelectedPlan(plan)}
                    >
                        {plan.type}
                    </div>
                ))}
            </div>

            <div className="floor-plan-details">
                <div className="floor-plan-image">
                    <img src={selectedPlan.image} alt={`${selectedPlan.type} Image`} />
                </div>
                <div className="floor-plan-text">
                    <table>
                        <tbody>
                            {Object.entries(selectedPlan).map(([key, value]) => {
                                if (key !== "image" && key !== "type") {
                                    return (
                                        <tr key={key}>
                                            <th>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}</th>
                                            <td>{value}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default NakshatraFloorPlan;
