import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import skyCity1 from '../Sky_city/sky_city_img1.jpg'
import skyCity2 from '../Sky_city/sky_city_img2.jpg'
import skyCity3 from '../Sky_city/sky_city_img3.jpg'
import skyCity4 from '../Sky_city/sky_city_img4.jpg'
import skyCity5 from '../Sky_city/sky_city_img5.jpg'
import FloorPlanDetails from "./SkyCityFloorPlan";
import SkyCityFloorPlan from "./SkyCityFloorPlan";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';



const getFlatTypePriceRanges = (flatType) => {
    if (!flatType || !Array.isArray(flatType)) return [];

    return flatType
        .filter((flat) => flat.builtUpAreas && flat.builtUpAreas.length > 0) // Ensure builtUpAreas exists and has entries
        .map((flat) => {
            const validAreas = flat.builtUpAreas.filter(
                (area) => area.value && !isNaN(parseFloat(area.value))
            );

            if (validAreas.length === 0) return null; // Skip invalid entries

            const smallest = validAreas.reduce((prev, curr) =>
                parseFloat(curr.value) < parseFloat(prev.value) ? curr : prev
            );

            const largest = validAreas.reduce((prev, curr) =>
                parseFloat(curr.value) > parseFloat(prev.value) ? curr : prev
            );

            return {
                type: flat.type,
                smallestValue: parseFloat(smallest.value),
                largestValue: parseFloat(largest.value),
                smallestPrice: smallest.expectedPrice,
                largestPrice: largest.expectedPrice,
            };
        })
        .filter((flat) => flat);
};

const getPriceAndAreaDetails = (flatType) => {
    // Filter out flatTypes with builtUpAreas that have valid values
    const validFlats = flatType.filter(
        (flat) =>
            flat.builtUpAreas &&
            flat.builtUpAreas.some(
                (area) => area.value && !isNaN(parseFloat(area.value))
            )
    );

    if (validFlats.length === 0) {
        return {
            smallest: null,
            largest: null,
            smallestCarpetArea: null,
            largestCarpetArea: null,
            typesWithData: [],
            typesWithValidValues: "",
        };
    }

    // Extract all valid builtUpArea values, expectedPrices, and carpet areas
    const allBuiltUpAreas = validFlats.flatMap((flat) =>
        flat.builtUpAreas
            .filter(
                (area) =>
                    area.value && area.expectedPrice && !isNaN(parseFloat(area.value))
            )
            .map((area) => ({
                type: flat.type,
                value: parseFloat(area.value), // Built-up area
                carpetArea: parseFloat(area.carpetArea) || null, // Carpet area
                expectedPrice: parseFloat(area.expectedPrice),
            }))
    );

    // If there's only one valid entry, return a single price and single carpet area
    if (allBuiltUpAreas.length === 1) {
        const entry = allBuiltUpAreas[0];
        return {
            smallest: entry,
            largest: null, // No need for largest since there's only one
            smallestCarpetArea: entry.carpetArea || null,
            largestCarpetArea: null, // No need for largest carpet area
            typesWithData: [entry.type],
            typesWithValidValues: entry.type,
            singlePrice: entry.expectedPrice,
        };
    }

    // Find the smallest and largest entries across all types
    const smallest = allBuiltUpAreas.reduce((prev, curr) =>
        curr.value < prev.value ? curr : prev
    );

    const largest = allBuiltUpAreas.reduce((prev, curr) =>
        curr.value > prev.value ? curr : prev
    );

    // Find the smallest and largest carpet areas
    const smallestCarpetArea = allBuiltUpAreas.reduce((prev, curr) =>
        curr.carpetArea !== null && curr.carpetArea < prev.carpetArea ? curr : prev
    );

    const largestCarpetArea = allBuiltUpAreas.reduce((prev, curr) =>
        curr.carpetArea !== null && curr.carpetArea > prev.carpetArea ? curr : prev
    );

    // Collect all unique types with valid data
    const typesWithData = [...new Set(allBuiltUpAreas.map((entry) => entry.type))];

    // Collect all unique types regardless of price validity
    const typesWithValidValues = [...new Set(validFlats.map((flat) => flat.type))].join(", ");

    return {
        smallest,
        largest,
        smallestCarpetArea,
        largestCarpetArea,
        typesWithData,
        typesWithValidValues,
        singlePrice: null, // Since there are multiple entries, return null for single price
    };
};


const formatPrice = (price) => {
    const num = parseInt(price, 10);
    if (isNaN(num)) return "Invalid Price";

    if (num >= 10000000) {
        // Convert to Crores
        return `${(num / 10000000).toFixed(2)} Cr`;
    } else if (num >= 100000) {
        // Convert to Lakhs
        return `${(num / 100000).toFixed(2)} L`;
    } else if (num >= 1000) {
        // Convert to Thousands
        return `${(num / 1000).toFixed(2)} K`;
    } else {
        // For amounts less than 1000
        return num.toString();
    }
};

const convertToINRWords = (amount) => {
    const words = [
        "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
        "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    const numToWords = (num) => {
        if (num < 20) return words[num];
        if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + words[num % 10] : "");
        if (num < 1000) return words[Math.floor(num / 100)] + " Hundred" + (num % 100 !== 0 ? " and " + numToWords(num % 100) : "");
        if (num < 100000) return numToWords(Math.floor(num / 1000)) + " Thousand" + (num % 1000 !== 0 ? " " + numToWords(num % 1000) : "");
        if (num < 10000000) return numToWords(Math.floor(num / 100000)) + " Lakh" + (num % 100000 !== 0 ? " " + numToWords(num % 100000) : "");
        return numToWords(Math.floor(num / 10000000)) + " Crore" + (num % 10000000 !== 0 ? " " + numToWords(num % 10000000) : "");
    };

    if (isNaN(amount)) return "";

    const wholePart = Math.floor(amount);
    const result = numToWords(wholePart);

    return `Rupees ${result} Only`;
};
const SkyCityDetailPage = () => {

    const [showPopup, setShowPopup] = useState(false);
    const { id } = useParams();
    const [data, setData] = useState({})
    const [load, setLoad] = useState(true)
    const [imageData, setImageData] = useState([])
    console.log(id);
    const navigate = useNavigate();
    const fullUrl = window.location.href;
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        address: "",
        remark: "",
        user_id: "HBTR0004",
        property_id: data.property_id,
        property_name: data.building_name,
        enquiry_source: "Details Page",
        source: fullUrl,
        role: "",
    });


    const fetchDataSingle = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/property/getsingleproperty/${id}`)
            const res = await response.json();
            const parseJSON = (data) => {
                try {
                    return JSON.parse(data);
                } catch {
                    return data; // Return the original data if parsing fails
                }
            };

            res.amenities = parseJSON(res.amenities || "[]");
            res.carpetArea = parseJSON(res.carpetArea || "[]");
            res.flatType = parseJSON(res.flatType || "[]");
            res.formImages = parseJSON(res.formImages || "[]");
            res.locationAdvantages = parseJSON(res.locationAdvantages || "[]");
            setData(res);
            setImageData(res.formImages)
            console.log(res);
        } catch (error) {
            console.log(error)
        }
    }
    // useEffect(() => {
    //     // Trigger popup after 5 seconds
    //     const timer = setTimeout(() => {
    //         setShowPopup(true);
    //     }, 30000);

    //     return () => clearTimeout(timer); // Clean up the timer on component unmount
    // }, []);

    useEffect(() => {
        // Check if the popup was already shown
        const popupShown = sessionStorage.getItem('popupShown');
        if (!popupShown) {
            // Trigger popup after 30 seconds
            const timer = setTimeout(() => {
                setShowPopup(true);
                sessionStorage.setItem('popupShown', 'true'); // Set flag in sessionStorage
            }, 30000);

            return () => clearTimeout(timer); // Clean up the timer on component unmount
        }
    }, []);
    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        // console.log(formData);
        setLoad(false);
        try {
            const response = await fetch(`https://100billionoms.com/leads`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            })
            if (response.ok) {
                const res = await response.json();
                console.log(res);
                setFormData({
                    name: "",
                    mobile: "",
                    remark: "",
                    address: "",
                    user_id: "HBTR0004",
                    enquiry_source: "Contact Page",
                    source: fullUrl,
                    property_id: data.property_id,
                    property_name: data.building_name,
                    role: "",
                })
                setLoad(true)
                toast.success('Details sent, Our team will contact you soon!');
            }
            setLoad(true)
        } catch (error) {
            console.log(error);
            toast.error('Error, Try Again');
            setLoad(true)
        }
    };

    const getEmbedLink = (link) => {
        if (!link) return ""; // Return an empty string if the link is null or undefined
        const match = link.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/))([\w-]+)/);
        return match ? `https://www.youtube.com/embed/${match[1]}` : link;
    };
    const { smallest, largest, typesWithData, typesWithValidValues } = data.flatType
        ? getPriceAndAreaDetails(data.flatType)
        : { smallest: null, largest: null, typesWithData: [], typesWithValidValues: null };
    const priceRanges = getFlatTypePriceRanges(data.flatType);

    useEffect(() => {
        fetchDataSingle();
    }, [])

    const images = [skyCity1, skyCity2, skyCity3, skyCity4, skyCity5]; // Example image array

    const renderImages = () => {
        const imgCount = imageData.length;

        return (
            <div className="image-wrapper">
                {imgCount <= 1 ? (
                    <div className="single-page-img1" onClick={() => navigate(`/gallery/${data.property_id}`)}>
                        <div className="img-container img-1">
                            <img src={`https://100billionoms.com/Images/Store/${imageData[0]}`} alt="Image 1" />
                        </div>
                    </div>
                ) : imgCount <= 6 ? (
                    <div className={`single-page-img${imgCount}`} onClick={() => navigate(`/gallery/${data.property_id}`)}>
                        {imageData.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={`https://100billionoms.com/Images/Store/${img}`} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="single-page-img6">
                        {imageData.slice(0, 5).map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index} onClick={() => navigate(`/gallery/${data.property_id}`)}>
                                <img src={`https://100billionoms.com/Images/Store/${img}`} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                        <div className="img-container img-6" onClick={() => navigate(`/gallery/${data.property_id}`)}>
                            <div className="overlay">{`${imgCount - 5}+ more images`}</div>
                        </div>
                    </div>
                )}

                {/* <div className="add-photos-btn-wrapper">
                    <input
                        type="file"
                        id="common-file-input"
                        className="common-file-input"
                        multiple
                    />
                    <label htmlFor="common-file-input" className="add-photos-btn">
                        + Add Photos
                    </label>
                </div> */}
            </div>
        );
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} />
            {/* Desktop Image Rendering */}
            <div className="single-page-img">{renderImages()}</div>

            {/* Mobile Slider */}
            <div className="single-page-img-mob">
                <Swiper
                    // modules={[Navigation]}
                    navigation={false}
                    pagination={{ clickable: false }}
                    slidesPerView={1}
                    spaceBetween={10}
                >
                    {imageData.map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className="img-slider-mob">

                                <img src={`https://100billionoms.com/Images/Store/${img}`} alt={`Slide ${index + 1}`} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className="detail-header">
                <div className="detail-header-list">
                    <div className="detail-building-name">
                        <h2>{data.building_name}</h2>
                    </div>
                    <div className="detail-building-address">
                        <p>{data.category ? data.category.charAt(0).toUpperCase() + data.category.slice(1) : ''} (Availability:- {data.availabilityStatus})</p>
                    </div>


                    <div className="detail-building-address">
                        <p>{data.address}</p>
                    </div>

                    <div className="detail-building-price">
                        {smallest && largest ? (
                            <p>
                                <strong>Price:</strong> ₹ {formatPrice(smallest.expectedPrice)} - ₹ {formatPrice(largest.expectedPrice)}
                            </p>
                        ) : smallest ? (
                            <p>
                                <strong>Price:</strong> ₹ {formatPrice(smallest.expectedPrice)}
                            </p>
                        ) : (
                            <p>Price not available</p>
                        )}
                    </div>
                    <div className="detail-building-address">
                        <p>Booking Amount:- {formatPrice(data.securityDeposit)} (Facing:- {data.facing})</p>
                    </div>
                    <div className="detail-builder-contact" onClick={() => setShowPopup(true)}>
                        <button className="contact-builder-btn">Download Cost Sheet </button>
                    </div>
                </div>

                <div className="detail-header-list">
                    <div className="detail-builder-contact">
                        <a href="tel:+919987809718"> <button className="contact-builder-btn">Contact Builder</button></a>
                    </div>
                </div>
            </div>

            <div className="detail-list-flex">
                <div className="detail-list-flex1">
                    <div className="detail-page-amenities">
                        <h3>Amenities</h3>
                        <div className="amenities-card">
                            <div className="amenities-list">
                                {/* New Amenities */}
                                {data.amenities && data.amenities.length > 0 ? (
                                    data.amenities.map((ele, idx) => (
                                        <div className="amenity-item" key={idx}>
                                            <span>{ele}</span>
                                        </div>
                                    ))
                                ) : (
                                    <p>No amenities available</p> // Fallback message
                                )}

                            </div>
                        </div>
                    </div>

                    <SkyCityFloorPlan data={data} />

                    <div className="detail-advantage">
                        <h3>Location advantage</h3>
                        <div className="location-details">
                            <div className="location-list">
                                <ul>

                                    {
                                        data.locationAdvantages && data.locationAdvantages.length > 0 ? (
                                            data.locationAdvantages.map((ele, idx) => (
                                                < li key={idx}>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {ele}
                                                    <span></span>
                                                </li>
                                            ))
                                        ) : (
                                            <p>No locationAdvantages available</p> // Fallback message
                                        )
                                    }


                                    {/* <li><i className="fas fa-map-marker-alt"></i> Railway Station <span>(1.5 KM)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Fun Fiesta Cinema <span>(5 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> D Mart <span>(5 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> McDonald's <span>(10 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Schools & Colleges <span>(5 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Shopping Mall <span>(10 min walking)</span></li> */}
                                </ul>
                            </div>
                            {/* <div className="location-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.426122335015!2d72.80831327521445!3d19.43718628184317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a9367387b2db%3A0x5b1d12ab70c158a6!2sSky%20City%20by%20Unique%20%26%20Poonam%20Developers!5e0!3m2!1sen!2sin!4v1736427582550!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div> */}
                        </div>
                    </div>

                    {
                        data && data.virtualTour === "" ? null :
                            <div className="detail-youtube">
                                <h3>Virtual Tour</h3>
                                <div className="youtube-link">
                                    <iframe width="560" height="315"
                                        src={getEmbedLink(data.virtualTour)}
                                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                    }

                </div>
                <div className="detail-list-flex2">
                    {/* Enquiry Form */}
                    <div className="enquiry-form">
                        <h3>Enquire About This Property</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your full name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Phone Number</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your phone number"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Location  <p className='location-newaskuser'>*Enter your location where you finding a property</p></label>
                                   
                                    <input
                                        type="text"
                                        id="number"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter location"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Budget</label>
                                    <input
                                        type="number"
                                        id="number"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your Budget"
                                    />
                                    {
                                        formData.role > 0 &&
                                        <small style={{ fontSize: "12px", color: "#3399ff", fontWeight: "600", marginTop: "3px" }}>
                                            {convertToINRWords(formData.role)}
                                        </small>
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="remark"
                                    value={formData.remark}
                                    onChange={handleChange}

                                    placeholder="Write your message here"
                                ></textarea>
                            </div>
                            {
                                load ? <button type="submit">Submit Enquiry</button> :
                                    <button type="button">Loading...</button>
                            }
                        </form>
                    </div>
                </div>
            </div>





            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <button className="popup-close-btn" onClick={closePopup}>
                            &times;
                        </button>
                        <div className="popup-content">
                            <h3>Enquire About This Property</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your full name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <input
                                            type="text"
                                            id="number"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Location <p className='location-newaskuser'>*Enter your location where you finding a property</p></label>
                                       
                                        <input
                                            type="text"
                                            id="number"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter location"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Budget</label>
                                        <input
                                            type="number"
                                            id="number"
                                            name="role"
                                            value={formData.role}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your Budget"
                                        />
                                        {
                                            formData.role > 0 &&
                                            <small style={{ fontSize: "12px", color: "#3399ff", fontWeight: "600", marginTop: "3px" }}>
                                                {convertToINRWords(formData.role)}
                                            </small>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleChange}

                                        placeholder="Write your message here"
                                    ></textarea>
                                </div>
                                {
                                    load ? <button type="submit">Submit Enquiry</button> :
                                        <button type="button">Loading...</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SkyCityDetailPage;
