import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const cleanDuplicates = (input) => {
    if (!input) return input; // Handle null or undefined cases
    const uniqueWords = Array.from(new Set(input.split(',').map((word) => word.trim())));
    return uniqueWords.join(', ');
};

const cleanPropertyData = (property) => {
    return {
        ...property,
        builderAgentName: cleanDuplicates(property.builderAgentName),
        reraRegistration: cleanDuplicates(property.reraRegistration),
        city: cleanDuplicates(property.city),
        locality: cleanDuplicates(property.locality),
        landmark: cleanDuplicates(property.landmark)
    };
};

const getPriceAndAreaDetails = (flatType) => {
    // Filter out flatTypes with builtUpAreas that have valid values
    const validFlats = flatType.filter(
        (flat) =>
            flat.builtUpAreas &&
            flat.builtUpAreas.some(
                (area) => area.value && !isNaN(parseFloat(area.value))
            )
    );

    if (validFlats.length === 0) return { smallest: null, largest: null, typesWithData: [] };

    // Extract all valid builtUpArea values and expectedPrices
    const allBuiltUpAreas = validFlats.flatMap((flat) =>
        flat.builtUpAreas
            .filter(
                (area) =>
                    area.value && area.expectedPrice && !isNaN(parseFloat(area.value))
            )
            .map((area) => ({
                type: flat.type,
                value: parseFloat(area.value),
                expectedPrice: parseFloat(area.expectedPrice),
            }))
    );

    // If there's only one valid entry, return it as both smallest and largest
    if (allBuiltUpAreas.length === 1) {
        return {
            smallest: allBuiltUpAreas[0],
            largest: allBuiltUpAreas[0],
            typesWithData: [allBuiltUpAreas[0].type],
        };
    }

    // Find the smallest and largest entries across all types
    const smallest = allBuiltUpAreas.reduce((prev, curr) =>
        curr.value < prev.value ? curr : prev
    );

    const largest = allBuiltUpAreas.reduce((prev, curr) =>
        curr.value > prev.value ? curr : prev
    );

    // Collect all unique types with valid data
    const typesWithData = [
        ...new Set(allBuiltUpAreas.map((entry) => entry.type)),
    ];

    return { smallest, largest, typesWithData };
};

const formatPrice = (price) => {
    const num = parseInt(price, 10);
    if (isNaN(num)) return "Invalid Price";

    if (num >= 10000000) {
        // Convert to Crores
        return `${(num / 10000000).toFixed(1)} Cr`;
    } else if (num >= 100000) {
        // Convert to Lakhs
        return `${(num / 100000).toFixed(1)} L`;
    } else if (num >= 1000) {
        // Convert to Thousands
        return `${(num / 1000).toFixed(1)} K`;
    } else {
        // For amounts less than 1000
        return num.toString();
    }
};
const NewLaunced = () => {
    let [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    let fetchCategories = async () => {
        try {
            let response = await fetch(`https://100billionoms.com/category/getcategory/HBTR0004`);
            let res = await response.json();

            console.log(res);
            // Parse the movie field from JSON string to array of objects
            res = res.filter((category) => (category.status === "Active"))
            res = res.map((category) => ({
                ...category,
                buildings: category.buildings ? JSON.parse(category.buildings) : [],
            }));
            console.log(res);
            setCategories(res);


        } catch (error) {
            console.log(error);

        }
    };


    useEffect(() => {
        fetchCategories();
    }, [])


    return (
        <>
            <div className="launced">
                    {categories.map((category) => {
                        return <div className="launched-width" key={category.id}>
                            <h1 className="launch-heading">{category.category}</h1>
                            <p className="launch-para">Explore projects crafted to redefine luxury living and comfort.</p>
                            <div className="new-launced">
                                {
                                    category.buildings.map((building, index) => {
                                        const { smallest, largest, typesWithData } = getPriceAndAreaDetails(building.flatType);
                                        console.log(smallest, largest, typesWithData)
                                        return <div onClick={() => navigate(`/detail-page/${building.property_id}`)} className="new-launced-card" key={`${category.id}-${index}`}>
                                            <div className="new-launced-img">
                                                <img src={building.formImages && building.formImages[0]
                                                    ? `https://100billionoms.com/Images/Store/${building.formImages[0]}`
                                                    : building
                                                }
                                                    alt={building.Builiding_name || 'Building'} />
                                            </div>
                                            <div className="new-launced-details">
                                                <h2>{building.building_name}</h2>
                                                <p className="address">
                                                    {building.locality && building.city
                                                        ? `${building.locality}, ${building.city}`.length > 30
                                                            ? `${building.locality}, ${building.city}`.substring(0, 30) + "..."
                                                            : `${building.locality}, ${building.city}`
                                                        : ""}
                                                </p>

                                                <div className="price-area">
                                                         {
                                                            smallest?.expectedPrice===largest?.expectedPrice ? <p className="price"> ₹ {formatPrice(smallest?.expectedPrice || 0)}</p> :
                                                             <p className="price"> ₹ {formatPrice(smallest?.expectedPrice || 0)} - ₹ {formatPrice(largest?.expectedPrice || 0)}</p> 
                                                         }
                                                            
                                                           

                                                    <div className="line"></div>
                                                    <p className="area">{
                                                        typesWithData.join("- ") == "" || typesWithData === null ? <span > {building.bedrooms} BHK</span> :
                                                            <span > {typesWithData.join("- ")}</span>
                                                    }</p>
                                                </div>
                                                <div className="launch-btn">
                                                    View Now
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }


                            </div>
                        </div>
                    })}

                </div>
            

        </>
    );
};


export default NewLaunced;