import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const Contact = () => {

    const fullUrl = window.location.href;
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        remark: "",
        user_id: "HBTR0004",
        enquiry_source: "Contact Page",
        source:fullUrl
    });

    

    const [load, setLoad] = useState(true)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(false)
        try {
            const response = await fetch(`https://100billionoms.com/leads`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            })
            if (response.ok) {
                const res = await response.json();
                console.log(res);
                setLoad(true);
                toast.success('Details sent, Our team will contact you soon!');
                setFormData({
                    name: "",
                    mobile: "",
                    remark: "",
                    user_id: "HBTR0004",
                    enquiry_source: "Contact Page",
                    source:fullUrl
                })
            }
            setLoad(true);
        } catch (error) {
            console.log(error);
            setLoad(true);
            toast.error('Error, Try Again');
        }
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className="contact-page">
                <h2>Contact us</h2>
            </div>
            <div className="contact-section">
                <div className="contact-container">
                    {/* Google Map Column */}
                    <div className="contact-map">
                    
                        <iframe
                           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.5910624166486!2d72.84666427520885!3d19.21305558201987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b778d82f5c83%3A0xc067a2a98f635275!2sBhoomi%20Solutions!5e0!3m2!1sen!2sin!4v1742811092486!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>

                    {/* Contact Form Column */}
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name
                                <input type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
                            </label>
                            {/* <label>
                                Email
                                <input type="email" name="email" placeholder="Enter your email" required />
                            </label> */}
                            <label>
                                Mobile Number
                                <input type="tel" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} required />
                            </label>
                            <label>
                                Message
                                <textarea name="remark" placeholder="Enter your message" value={formData.remark} onChange={handleChange} required></textarea>
                            </label>
                            {
                                load ?<button type="submit">Submit</button>:
                                <button type="button">Loading...</button>
                            }
                            
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;