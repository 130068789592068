import React from 'react';
import Mission from '../img/mission.webp'
import Vision from '../img/vision.webp'
import coreValue from '../img/core value.webp'

const About = () => {
    return (
        <>
            <div className="about-main">
                <div className="about-img">
                    <h2>10</h2>
                    <h3>Years of Expertise in <br/>Real Estate</h3>
                </div>
                <div className="about-details">
                    <h4>Discover Our Journey</h4>
                    <h2>We Are The Premier Real Estate Firm in Your City</h2>
                    <p>
                        At Bhoomi solutions, we specialize in providing exceptional real estate services, offering a diverse portfolio of properties that cater to all your needs. With 10 years of expertise, our team has earned a reputation for being the trusted name in the industry, helping clients find their dream homes and secure lucrative investment opportunities.
                        <br /><br />
                        Whether you're looking to buy, sell, or invest, we provide personalized services that make the entire process smooth and hassle-free. Our in-depth market knowledge and client-centered approach ensure that every transaction is conducted with integrity and professionalism. Join us as we continue to redefine the real estate landscape and deliver top-tier results.
                    </p>
                </div>
            </div>
            <div className="about-mission-vision">
                <div className="mission">
                    <h3>Our Mission</h3>
                    <img src={Mission} alt="Mission" />
                    <p>
                        Our mission is to provide unmatched real estate services that empower our clients to make informed decisions. We are committed to offering personalized, transparent, and efficient real estate solutions that help individuals and businesses find the perfect properties. Our aim is to foster lasting relationships built on trust and mutual respect.
                    </p>
                </div>

                <div className="vision">
                    <h3>Our Vision</h3>
                    <img src={Vision} alt="Vision" />
                    <p>
                        Our vision is to be the go-to real estate firm, recognized for our integrity, market expertise, and commitment to delivering exceptional value. We strive to redefine the real estate experience, making it more accessible, efficient, and customer-centric. Through our innovative approach, we aim to be the first choice for buyers, sellers, and investors alike.
                    </p>
                </div>
            </div>

            {/* Values */}
            <div className="about-values">
                <h3>Our Core Values</h3>
                <img src={coreValue} alt="Core Values" />
                <ul>
                    <li><strong>Integrity</strong> - We operate with the highest level of honesty, transparency, and professionalism in all transactions.</li>
                    <li><strong>Client-Centered</strong> - Our clients’ needs always come first, and we tailor our services to meet their goals.</li>
                    <li><strong>Excellence</strong> - We strive for perfection in every aspect of our business, delivering top-tier services and results.</li>
                    <li><strong>Innovation</strong> - We use the latest technology and trends to stay ahead in the ever-evolving real estate market.</li>
                    <li><strong>Community Focused</strong> - We are dedicated to helping build vibrant communities by providing exceptional properties and services.</li>
                </ul>
            </div>
        </>
    );
}

export default About;
