import React from 'react';
import { Link } from 'react-router-dom';
import justdial from '../img/just dial logo.png'
import instagram from "../img/ai-generated-instagram-logo-free-png.webp"
import facebook from "../img/images (7).png"
import whatapp from "../img/whatsapp-icon-free-png.webp"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <h2>BHOOMI SOLUTIONS</h2>
          <p>Your Trusted Real Estate Partner</p>
        </div>
        <div className="footer-links">
          <div className="footer-link-column">
            <h4>Quick Links</h4>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to='/my-projects'>My Projects</Link></li>
              <li><Link to='/about'>About</Link></li>
              <li><Link to='/contact'>Contact us</Link></li>
            </ul>
          </div>
          <div className="footer-link-column">
            <h4>Contact Us</h4>
            <ul>
              <li>Email: <a href="mailto:info@bhoomisolutions.in">info@bhoomisolutions.in</a></li>
              <li>Phone: <a href="tel:+918446064275">+91 9987809718 </a></li>
              <li>RERA NO: <span>A51800021237</span>
              </li>
              <li>Address: <span>S/159, 2nd Floor,Raghuleela Mega Mall, Behind Poisar Bus Depot, Kandivali West, Mumbai-400 067</span>
              </li>
              <li className='footer-social-icon'>
                <a href="https://www.justdial.com/Mumbai/Bhoomi-Solutions-Mahavir-Nagar-Near-Poisar-Bus-Depot-Kandivali-West/022PXX22-XX22-140731183057-M6V4_BZDET">
                {/* <i className="fa-brands fa-youtube"></i> */}
                <img src={justdial} className='youtubebest' alt="" />
                </a>
                <a href="https://www.instagram.com/bhoomisolutions/">
                {/* <i className="fa-brands fa-instagram"></i> */}
                <img src={instagram} className='instabest' alt="" />
                </a>
                <a href="https://www.facebook.com/bhoomisolutionsmumbai/">
                {/* <i className="fa-brands fa-facebook"></i> */}
                <img src={facebook} className='facebookbest' alt="" />
                </a>
                <a
                  href="https://wa.me/9987809718?text=Hello%21%20I%20am%20interested%20in%20properties."
                  
                  target="_blank"
                  rel="noopener noreferrer">
                {/* <i className="fa-brands fa-facebook"></i> */}
                <img src={whatapp} className='whatsappbest' alt="" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-news">
            <h4>Legal Terms</h4>
            <ul>
              <li><Link to="/terms-and-conditions">Terms and conditions</Link></li>
              <li><Link to="/refund-policy">Refund policy</Link></li>
              <li><Link to="/privacy-policy">Privacy policy</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; All rights reserved, Powered by <a href="https://www.100billiontech.com/">100billion Tech</a></p>
      </div>
    </footer>
  );
};

export default Footer;
