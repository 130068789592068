import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import evershineImg1 from '../Evershine/evershine_img1.jpg'
import evershineImg2 from '../Evershine/evershine_img2.jpg'
import evershineImg3 from '../Evershine/evershine_img3.jpg'
import evershineImg4 from '../Evershine/evershine_img4.jpg'
import evershineImg5 from '../Evershine/evershine_img5.jpg'
import NakshatraFloorPlan from "./NakshatraFloorPlan";
import EvershineFloorPlan from "./EvershineFloorPlan";

const EvershineDetailPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        number: "",
        email: "",
        message: "",
    });

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Trigger popup after 5 seconds
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 30000);

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
    };

    const images = [evershineImg1, evershineImg2, evershineImg3, evershineImg4, evershineImg5]; // Example image array

    const renderImages = () => {
        const imgCount = images.length;

        return (
            <div className="image-wrapper">
                {imgCount <= 1 ? (
                    <div className="single-page-img1">
                        <div className="img-container img-1">
                            <img src={images[0]} alt="Image 1" />
                        </div>
                    </div>
                ) : imgCount <= 6 ? (
                    <div className={`single-page-img${imgCount}`}>
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="single-page-img6">
                        {images.slice(0, 5).map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                        <div className="img-container img-6">
                            <div className="overlay">{`${imgCount - 5}+ more images`}</div>
                        </div>
                    </div>
                )}

                <div className="add-photos-btn-wrapper">
                    <input
                        type="file"
                        id="common-file-input"
                        className="common-file-input"
                        multiple
                    />
                    <label htmlFor="common-file-input" className="add-photos-btn">
                        + Add Photos
                    </label>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Desktop Image Rendering */}
            <div className="single-page-img">{renderImages()}</div>

            {/* Mobile Slider */}
            <div className="single-page-img-mob">
                <OwlCarousel
                    className="owl-carousel"
                    items={1} // Display one image at a time
                    loop
                    margin={10}
                    nav
                    navText={[
                        '<i class="fas fa-chevron-left"></i>',
                        '<i class="fas fa-chevron-right"></i>'
                    ]}
                    dots // Pagination dots
                >
                    {images.map((img, index) => (
                        <div key={index} className="item">
                            <div className="img-slider-mob">
                                <img src={img} alt={`Slide ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>

            <div className="detail-header">
                <div className="detail-header-list">
                    <div className="detail-building-name">
                        <h2>Evershine</h2>
                    </div>

                    <div className="detail-building-address">
                        <p>-</p>
                    </div>

                    <div className="detail-building-price">
                        <p><strong>Price:</strong> 48.20L - 74.48L </p>
                    </div>
                </div>

                <div className="detail-header-list">
                    <div className="detail-builder-contact">
                        <a href="tel:+918446064275"> <button className="contact-builder-btn">Contact Builder</button></a>
                    </div>
                </div>
            </div>

            <div className="detail-list-flex">
                <div className="detail-list-flex1">
                    <div className="detail-page-amenities">
                        <h3>Amenities</h3>
                        <div className="amenities-content">
                            <ul>
                                <li>Breath-taking beautiful elevation</li>
                                <li>Spacious & premium finished naturally ventilated entrance lobby and typical floor lift lobbies</li>
                                <li>Spacious well-designed 1 & 2 BHK apartment with cross ventilation in living, dining, and master bedroom</li>
                                <li>Fully finished kitchen with hob, chimney, water purifier, trolleys, and cabinets</li>
                                <li>Premium fittings from Cera and Jaguar in powder room and washroom</li>
                                <li>Pre-installed video door phones</li>
                                <li>Inbuilt inverter in each apartment</li>
                                <li>Internet provision in each apartment</li>
                            </ul>
                            <h4>Podium Amenities</h4>
                            <ul>
                                <li>Multi-purpose Court</li>
                                <li>Children’s Playground</li>
                                <li>Activity Lawn</li>
                                <li>Sand Pit</li>
                                <li>Yoga Deck</li>
                                <li>Giant Outdoor Chess Board</li>
                                <li>Timber Deck</li>
                                <li>Cascading Planter</li>
                                <li>Amphitheatre</li>
                                <li>Hammock Lounge</li>
                                <li>Dry garden</li>
                                <li>Open Gym</li>
                                <li>Reading Corner</li>
                                <li>Teenagers Area</li>
                                <li>Senior Citizens Corner</li>
                                <li>Swimming Pool</li>
                                <li>Squash Courts</li>
                                <li>Indoor Badminton Courts</li>
                                <li>Basketball Courts</li>
                                <li>Lawn Tennis Court</li>
                                <li>Gymnasium</li>
                                <li>Yoga Centre</li>
                                <li>Indoor Games Room</li>
                                <li>Banquets, restaurants</li>
                            </ul>
                            <h4>Investment USP</h4>
                            <ul>
                                <li>Most luxurious project in the Global City</li>
                                <li>Larger flat layouts</li>
                                <li>Biggest Balcony</li>
                            </ul>
                        </div>
                    </div>
                    <EvershineFloorPlan />

                    <div className="detail-advantage">
                        <h3>Location advantage</h3>
                        <div className="location-details">
                            <div className="location-list">
                                <ul>
                                    <li><i className="fas fa-map-marker-alt"></i> Railway Station <span>(2 KM)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Fun Fiesta Cinema <span>(2 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> D Mart <span>(15 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> McDonald's <span>(10 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Schools & Colleges <span>(5 min walking)</span></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Shopping Mall <span>(5 min walking)</span></li>
                                </ul>
                            </div>
                            {/* <div className="location-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.749311958006!2d72.80232747374679!3d19.423234540982747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a95b21e3e697%3A0x4a90644a230b294b!2sNakshatra%20Auris!5e0!3m2!1sen!2sin!4v1736430529496!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div> */}
                        </div>
                    </div>

                    <div className="detail-youtube">
                        <h3>Virtual Tour</h3>
                        <div className="youtube-link">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/jPkBJY1KI_Q?si=ScTpro5wx0tSRWA6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="detail-list-flex2">
                    {/* Enquiry Form */}
                    <div className="enquiry-form">
                        <h3>Enquire About This Property</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your full name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Phone Number</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="number"
                                        value={formData.number}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your phone number"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    placeholder="Write your message here"
                                ></textarea>
                            </div>
                            <button type="submit">Submit Enquiry</button>
                        </form>
                    </div>
                </div>
            </div>





            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <button className="popup-close-btn" onClick={closePopup}>
                            &times;
                        </button>
                        <div className="popup-content">
                            <h3>Enquire About This Property</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your full name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <input
                                            type="text"
                                            id="number"
                                            name="number"
                                            value={formData.number}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        placeholder="Write your message here"
                                    ></textarea>
                                </div>
                                <button type="submit">Submit Enquiry</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EvershineDetailPage;
