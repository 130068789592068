import React, { useState, useEffect } from 'react';

import skyCity from '../Sky_city/sky_city_img1.jpg'
import nakshatra from '../Nakshtra_Auris/nakshtra_img1.jpg'
import evershine from '../Evershine/evershine_img1.jpg'
import RealEstateCarousel from './RealEstateCards';
import { toast, ToastContainer } from 'react-toastify';
import FeaturedCollection from './FeaturedCollection';
import NewLaunced from './NewLaunced';
import TopBuilders from './TopBuilders';

const Home = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    // const slides = [
    //     { img: skyCity, title: 'Sky city' },
    //     { img: nakshatra, title: 'nakshatra auris' },
    //     { img: evershine, title: 'evershine' },
    // ];
    const [slides, setSlides] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const fullUrl = window.location.href;
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        address: "",
        remark: "",
        user_id: "HBTR0004",
        enquiry_source: "Home Page",
        source: fullUrl,
        role: "",
    });

    const convertToINRWords = (amount) => {
        const words = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
            "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
            "Sixteen", "Seventeen", "Eighteen", "Nineteen"
        ];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        const numToWords = (num) => {
            if (num < 20) return words[num];
            if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + words[num % 10] : "");
            if (num < 1000) return words[Math.floor(num / 100)] + " Hundred" + (num % 100 !== 0 ? " and " + numToWords(num % 100) : "");
            if (num < 100000) return numToWords(Math.floor(num / 1000)) + " Thousand" + (num % 1000 !== 0 ? " " + numToWords(num % 1000) : "");
            if (num < 10000000) return numToWords(Math.floor(num / 100000)) + " Lakh" + (num % 100000 !== 0 ? " " + numToWords(num % 100000) : "");
            return numToWords(Math.floor(num / 10000000)) + " Crore" + (num % 10000000 !== 0 ? " " + numToWords(num % 10000000) : "");
        };

        if (isNaN(amount)) return "";

        const wholePart = Math.floor(amount);
        const result = numToWords(wholePart);

        return `Rupees ${result} Only`;
    };
    const [load, setLoad] = useState(true)

    useEffect(() => {
        // Check if the popup was already shown
        const popupShown = sessionStorage.getItem('popupShown');
        if (!popupShown) {
            // Trigger popup after 30 seconds
            const timer = setTimeout(() => {
                setShowPopup(true);
                sessionStorage.setItem('popupShown', 'true'); // Set flag in sessionStorage
            }, 30000);

            return () => clearTimeout(timer); // Clean up the timer on component unmount
        }
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        // console.log(formData);
        setLoad(false)
        try {
            const response = await fetch(`https://100billionoms.com/leads`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            })
            if (response.ok) {
                const res = await response.json();
                console.log(res);
                setFormData({
                    name: "",
                    mobile: "",
                    remark: "",
                    address: "",
                    user_id: "HBTR0004",
                    enquiry_source: "Contact Page",
                    source: fullUrl,
                    role: "",

                })
                setLoad(true)
                closePopup();
                toast.success('Details sent, Our team will contact you soon!');
            }
            setLoad(true)
        } catch (error) {
            console.log(error);
            toast.error('Error, Try Again');
            setLoad(true)
        }
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    };

    // useEffect(() => {
    //     const autoSlide = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    //     return () => clearInterval(autoSlide); // Cleanup on unmount
    // }, [currentSlide]);

    const fetchSliderData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/slider/getslider/HBTR0004`)
            const res = await response.json();
            setSlides(res.reverse());
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }
    const handleClickChange = (link) => {
        if (link) {
            window.open(link, '_blank');
        } else {
            return
        }
    }

    useEffect(() => {
        fetchSliderData();
    }, [])

    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className="home-slider">
                <div className="home-slider-prev-btn" onClick={prevSlide}>
                    <i className="fa-solid fa-chevron-left"></i>
                </div>
                <div
                    className="home-slider-wrapper"
                    style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                        transition: 'transform 0.5s ease-in-out',
                    }}
                >
                    {slides.map((slide, index) => (
                        <div key={`${slide.name}-${index}`} className="home-slider-list">
                            <div className="home-slider-img" onClick={() => handleClickChange(slide.link)}>
                                <img src={`https://100billionoms.com/${slide.image}`} alt={slide.name} />
                            </div>
                            <div
                                className={`home-slider-details ${currentSlide === index ? 'active' : ''}`}
                            >
                                <h2 onClick={() => handleClickChange(slide.link)}>{slide.name}</h2>
                            </div>
                            <div className="popup-btn" onClick={() => setShowPopup(true)}>
                                Get in touch
                            </div>
                        </div>
                    ))}
                </div>
                <div className="home-slider-next-btn" onClick={nextSlide}>
                    <i className="fa-solid fa-chevron-right"></i>
                </div>
            </div>

            <div className="about-main">
                <div className="about-img">
                    <h2>10</h2>
                    <h3>Years experience</h3>
                </div>
                <div className="about-details">
                    <h4>Discover Our Journey</h4>
                    <h2>We Are The Premier Real Estate Firm in Your City</h2>
                    <p>
                        At Bhoomi solutions, we specialize in providing exceptional real estate services, offering a diverse portfolio of properties that cater to all your needs. With 10 years of expertise, our team has earned a reputation for being the trusted name in the industry, helping clients find their dream homes and secure lucrative investment opportunities.
                        <br /><br />
                        Whether you're looking to buy, sell, or invest, we provide personalized services that make the entire process smooth and hassle-free. Our in-depth market knowledge and client-centered approach ensure that every transaction is conducted with integrity and professionalism. Join us as we continue to redefine the real estate landscape and deliver top-tier results.
                    </p>
                </div>
            </div>
            <FeaturedCollection />
            <NewLaunced />
            <TopBuilders/>

            <RealEstateCarousel />


            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <button className="popup-close-btn" onClick={closePopup}>
                            &times;
                        </button>
                        <div className="popup-content">
                            <h3>Enquire About This Property</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your full name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <input
                                            type="text"
                                            id="number"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Location
                                            <p className='location-newaskuser'>*Enter your location where you finding a property</p>
                                        </label>
                                        <input
                                            type="text"
                                            id="number"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter location"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Budget</label>
                                        <input
                                            type="number"
                                            id="number"
                                            name="role"
                                            value={formData.role}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your Budget"
                                        />
                                        {
                                            formData.role > 0 &&
                                            <small style={{ fontSize: "12px", color: "#3399ff", fontWeight: "600", marginTop: "3px" }}>
                                                {convertToINRWords(formData.role)}
                                            </small>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleChange}
                                        placeholder="Write your message here"
                                    ></textarea>
                                </div>
                                {load ? <button type="submit">Submit Enquiry</button>
                                    : <button type="button">Loading...</button>

                                }

                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Home;
